.viewer-bkg {
  background-color: #000;
  position: absolute;
  top:0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 640px;
  height: 480px;
}
